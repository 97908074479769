<template>
	<h1>Pagination</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre
			v-highlightjs
		><code class="language-javascript">import BasePagination from '@/components/BasePagination'

export default {
	components: { BasePagination },
	props: {
		pageCount: {
			type: Number,
			required: true,
		},
		page: {
			type: [Number, String],
			default: 1,
		},
	},
	created() {
		if (!this.$route.params.page) {
			this.$route.params.page = 1
			this.$router.replace(this.$route)
		}
	},
}
</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation</h3>
		<ul>
			<li>
				Set up router so <span class="code">pageCount</span> and <span class="code">page</span> are passed as
				props.
			</li>
			<li>The <span class="code">range</span> parameter is optional, default is 10.</li>
			<li>
				When no page is set, forward to page #1 so the pagination highlight works. Make sure to use
				<span class="code">$router.replace()</span> instead of <span class="code bad">$router-push()</span> to
				avoid history pollution.
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :range="5" :currentPage="page" />
	</div>
</template>

<script>
import BasePagination from '@/components/BasePagination'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocComponentsPagination',
	components: { BasePagination },
	setup() {
		injectMetaData({
			title: 'Doc: Pagination Component',
		})
	},
	data() {
		return {
			pageCount: 120,
			page: 1,
			html: '<BasePagination v-if="pageCount > 1" :pageCount="pageCount" :range="5" :currentPage="page" />',
		}
	},
	created() {
		if (!this.$route.params.page) {
			this.$route.params.page = 1
			this.$router.replace(this.$route)
		}
	},
	methods: {},
}
</script>
